// flex
.d-flex { display: flex; }
.flex { flex: 1 };
.flex-no-shrink { flex-shrink: 0 };
.f-row { flex-direction: row; }
.f-col { flex-direction: column; }
.f-wrap { flex-wrap: wrap; }

// flex align and justify
.ai-center { align-items: center; }
.jc-center { justify-content: center; }
.jc-space-between { justify-content: space-between; }
.jc-space-around { justify-content: space-around; }
.jc-end { justify-content: flex-end; }

// text transform
.upper-case { text-transform: uppercase; }

// font-weight
@for $i from 1 to 8 {
  .fw-#{$i * 100} { font-weight : $i * 100; };
}

.rounded-border { border-radius: 8px }

// sizes
.h-100 { height: 100%; }
.w-100 { width: 100% }
.h-80 { height: 80vh; }

// background colors
.bg-white { background-color: white; }

.space-left { margin-left: 15px; }
.space-right { margin-right: 15px; }
.space-top { margin-top: 15px; }
.space-bottom { margin-bottom: 15px; }
.space-all { margin: 15px; }
.space-left-p { padding-left: 15px; }
.space-right-p { padding-right: 15px; }
.space-top-p { padding-top: 15px; }
.space-bottom-p { padding-bottom: 15px; }
.space-all-p { padding: 15px; }

.space-left-1 { margin-left: 8px; }
.space-right-1 { margin-right: 8px; }
.space-top-1 { margin-top: 8px; }
.space-bottom-1 { margin-bottom: 8px; }
.space-all-1 { margin: 8px; }
.space-left-p-1 { padding-left: 8px; }
.space-right-p-1 { padding-right: 8px; }
.space-top-p-1 { padding-top: 8px; }
.space-bottom-p-1 { padding-bottom: 8px; }
.space-all-p-1 { padding: 8px; }

.btn-spaces-vertical { margin: 5px; }
.cursor-pointer {cursor: pointer;}

.text-overflow-conteiner {
  overflow: hidden;
  white-space: nowrap;
}

.text-overflow-ellipse{
  text-overflow: ellipsis " ...";
}

.font-size-bold{
  font-size: 18px;
  font-weight: 900;
}

.overflow-x-scroll {
  overflow-x: scroll;
}
.overflow-y-scroll {
  overflow-y: scroll;
}