.my-table {
  margin-top: 24px;

  .ant-table-cell {
    background: white;
  }

  thead {
    .ant-table-cell {
      font-size: 16px;
      font-weight: 700;
      letter-spacing: 0.005em;

      .ant-table-column-sorter {
        color: #008540;
        .ant-table-column-sorter-down {
          font-size: 16px;
        }
        .ant-table-column-sorter-up {
          font-size: 16px;
        }
      }
    }
  }

  td.ant-table-cell {
    padding-top: 24px;
    padding-bottom: 23px;
  }

  .ant-table-selection-column {
    padding-left: 20px;
    padding-right: 20px;
  }

  .ant-checkbox-wrapper {
    .ant-checkbox-inner {
      width: 24px;
      height: 24px;
      &::after {
        height: 14px;
        width: 9px;
      }
    }
    .ant-checkbox-indeterminate {
      .ant-checkbox-inner::after {
        background-color: #008540;
        width: 14px;
      }
    }
  }

  .ant-table-tbody > tr.ant-table-row:hover > td {
    background-color: #e9fff3;
  }

  .ant-table-expanded-row.ant-table-expanded-row-level-1 {
    background-color: white;
  }
}
