.reports {
  .reports-header {
    font-size: 20px;
    font-weight: 600;
    color: #008540;
    padding-top: 10px;
  }
  .subtitle {
    font-size: 14px;
    font-weight: 500;
    color: #222B45;
    padding-top: 8px;
  }

  .reports-date-range-selector {
    padding-top: 25px;
    font-size: 16px;
    font-weight: 600;
  }

  .tile-container {
    .report-tile {
      width: 472px;
      margin: 16px 0;
      padding: 8px;

      font-size: 14px;
      font-weight: 500;

      box-shadow: 2px 6px 9px -2px rgba(0,0,0,0.5);
      -webkit-box-shadow: 2px 6px 9px -2px rgba(0,0,0,0.5);
      -moz-box-shadow: 2px 6px 9px -2px rgba(0,0,0,0.5);

      img {
        padding: 12px;
        border-right: 1px solid #EDF2F6;
        width: 53px;
      }
      .anticon {
        font-size: 25px;
        padding: 12px;
        color: #008540;
      }
      .date {
        flex: 0 0 100px;
      }
      .name {
        flex: 0 0 150px
      }
    }
  }
}
