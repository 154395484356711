.chambers-view {
  .site-name {
    font-size: 20px;
    font-weight: 600;
    color: #008540;
  }

  .site-sub-title, .contracts-title {
    font-size: 18px;
    font-weight: 400;
  }
  .contracts-contact {
    font-size: 14px;
    font-weight: 400;
  }

  .detail-section {
    margin: 10px 70px;
    padding: 23px 43px;
    box-shadow: 2px 6px 9px -2px rgba(0,0,0,0.2);
    -webkit-box-shadow: 2px 6px 9px -2px rgba(0,0,0,0.2);
    -moz-box-shadow: 2px 6px 9px -2px rgba(0,0,0,0.2);

    .service-cards {
      justify-content: space-around;
      margin: 20px 0;
      .service-card {
        padding: 20px;
        border: 1px solid #008540;
        width: 375px;

        .title {
          img {
            width: 36px;
            height: 36px;
          }
          .text {
            font-size: 13px;
            font-weight: 600;
            padding-left: 11px;
          }
        }
        .date {
          font-size: 26px;
          font-weight: 600;
          padding-left: 8px;
        }
      }
    }
    .view-past {
      font-size: 12px;
      margin-bottom: 24px;
    }
    .person-count {
      color: #008540;
      font-size: 12px;
      span {
        font-size: 20px;
      }
    }

    .details-table {
      display: flex;
      flex-direction: column;
      padding-top: 28px;
      padding-bottom: 40px;
      .dt-row {
        display: flex;
        flex-direction: row;
        padding: 12px;
        align-items: center;
        justify-content: space-between;
        border-radius: 8px;
        &:nth-child(even) {
          background-color: #F2F2F2;
        }
        .dt-key {
          font-size: 12px;
          font-weight: 500;
          flex: 1;
        }
        .dt-value {
          font-size: 12px;
          color: #687C97;
          flex: 1;
        }
      }
    }
  }
}
