.chambers-view {
  .chambers-view-tile {
    padding: 24px;
    box-shadow: 2px 6px 9px -2px rgba(0,0,0,0.5);
    -webkit-box-shadow: 2px 6px 9px -2px rgba(0,0,0,0.5);
    -moz-box-shadow: 2px 6px 9px -2px rgba(0,0,0,0.5);
    .left {
      img {
        background-color: #BDC9D3;
        width: 72px;
        height: 72px;
        margin-right: 16px;
      }
      .names {
        .title {
          font-size: 18px;
          margin-bottom: 10px;
        }
        .sub-title {
          color: #687C97;
          font-size: 12px;
          line-height: 14px;
        }
      }
    }
  }
}
.ant-modal.resume-service-modal {
  .ant-modal-content {
    padding: 30px;
  }
  .resume-service-modal-content {
    text-align: center;
    .header-icon span {
      font-size: 45px;
      color: #008540;
    }
    .header-text {
      margin-top: 14px;
      font-size: 20px;
      line-height: 30px;
      color: #008540;
    }
    .mine {
      margin-top: 17px;
      font-size: 18px;
      line-height: 22px;
    }

    .site, .chamber {
      margin-top: 10px;
      font-size: 13px;
      line-height: 16px;
    }
    .question {
      margin: 20px;
      color: #687C97;
      font-size: 13px;
    }
  }
}
