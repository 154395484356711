.add-company-form {
  .company-description-editor {
    position: relative;
    .quill {
      height: 150px;

      .ql-toolbar.ql-snow {
        border-radius: 8px;
      }

      .ql-container.ql-snow {
        margin-top: 24px;
        border-top: 1px solid #ccc;
        border-radius: 8px;
      }
      &.error {
        .ql-container.ql-snow {
          border-color: red;
        }
      }
    }
    .error-msg {
      position: absolute;
      bottom: 6px;
      padding-left: 20px;
      font-size: 11px;
      color: red
    }

    padding-bottom: 88px;
  }
  .upload-container {
    flex-basis: 315px;
    flex-grow: 0;

    .form-section-content {
      height: 100%;
    }
  }
}
