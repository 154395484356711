.overview {

    .fw-500 {
        font-size: 22px !important;
    }

    .left-100v {
        width: 100% !important;
    }

    .left {
        transition: all 0.15s;
        width: 75%;

        .list-wrapper {
            overflow-y: scroll;
            overflow-x: hidden;
            height: 62vh;
        }

        .ant-spin-nested-loading {
            border: 1px solid #E2E5EF;
            padding: 4px;
            margin-right: 4px;
            margin-top: 8px;
            border-radius: 8px;
        }

        .progress-summary-list {
            .ant-spin-nested-loading {
                border: none;

                .ant-list-item {
                    border: 1px solid #E2E5EF;
                    border-radius: 4px;
                    margin-left: 2px;
                    margin-right: 2px;
                    margin-bottom: 8px;

                    .card {
                        display: flex;
                        justify-content: space-between;
                        width: 100%;

                        .chamber-info {
                            margin-left: 8px;
                            margin-right: 8px;
                            align-items: center;

                            .text {
                                margin-left: 8px;
                            }
                        }

                        .progress-info {
                            margin-left: 8px;
                            margin-right: 8px;
                            align-items: center;

                            .info-card {
                                margin-left: 10px;
                                width: 100px;

                                .d-flex {
                                    justify-content: space-between;
                                }
                            }
                        }

                        .person-info {
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: flex-start;
                            margin-left: 8px;
                            margin-right: 8px;

                            .wrapper {
                                padding: 4px;

                                img {
                                    margin-left: 4px;
                                    margin-right: 4px;
                                }
                            }
                        }

                        .see-more {
                            margin-right: 8px;
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }

    .right {
        width: 25%;

        .list-wrapper {
            overflow-y: scroll;
            overflow-x: hidden;
            height: 65vh;
        }

        .ant-spin-nested-loading {
            border: 1px solid #E2E5EF;
            padding: 4px;
            margin-left: 4px;
            margin-top: 8px;
            border-radius: 8px;
        }

        .summary-list {
            .ant-spin-nested-loading {
                border: none;

                .ant-list-item {
                    border: 1px solid #E2E5EF;
                    border-radius: 4px;
                    margin-left: 2px;
                    margin-right: 2px;
                    margin-bottom: 8px;

                    .card {
                        display: flex;
                        margin-left: 8px;
                        margin-right: 8px;
                        align-items: center;
                        justify-content: space-between;
                        width: 100%;

                        .info {
                            justify-content: center;
                            align-items: center;
                            margin-left: 8px;

                            .pipe {
                                margin-left: 4px;
                                margin-right: 4px;
                            }
                        }

                        .see-more {
                            margin-right: 10px;
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }
}

.overview-chamber-view {
    .card-container {
        width: 25%;
        border: 1px solid #E2E5EF;
        padding: 4px;
        margin-right: 4px;
        margin-top: 8px;
        border-radius: 8px;

        .card-wrapper{
            height: 62vh;
            overflow-y: auto;
            overflow-x: hidden;

            .card {
                border: 1px solid #bababa;
                padding: 4px;
                border-radius: 8px;
                margin-top: 12px;
    
                .title {
                    margin-top: 10px;
                    margin-left: 5px;
                    margin-right: 5px;
                }
    
                .subtitle {
                    margin-left: 5px;
                    margin-right: 5px;
                }
    
                .progress {
                    margin-left: 5px;
                    margin-right: 5px;
                    margin-top: 10px;
                }
    
                .footer {
                    margin-left: 5px;
                    margin-right: 5px;
                    margin-top: 10px;
                    display: flex;
                    justify-content: space-between;
    
                    .left-button {
                        background-color: #edf7ff;
                        border-radius: 4px;
                        padding-left: 10px;
                        padding-right: 10px;
                        padding-top: 5px;
                        padding-bottom: 5px;
                        display: inline-block;
                    }
    
                    img {
                        cursor: pointer;
                    }
                }
            }
    
            hr {
                margin-bottom: 20px;
            }
    
            .verticle-line {
                background-color: #D9D9D9;
                width: 2px;
                position: absolute;
                bottom: 0;
                top: 0;
                margin-top: 220px;
                margin-left: 24px;
                margin-bottom: 4px;
            }
    
            .summary-card {
                display: flex;
                align-items: center;
                padding-bottom: 20px;
    
                .icon {
                    display: flex;
                    min-width: 30px;
                    min-height: 30px;
                    margin-left: 10px;
                    border-radius: 10px;
                    justify-content: center;
                    align-items: center;
                    z-index: 100;
                }
    
                .finished {
                    background-color: #0085FF;
                }
    
                .started {
                    background-color: #5DB95D;
                }
    
                .text-container {
                    margin-left: 5px;
                    align-items: flex-start;
                }
            }
        }

        .h40{
            height: 55vh; 
        }
    }
}

.top-bar {
    .ant-input-affix-wrapper {
        border-radius: 10px;
        width: 450px;
        height: 46px;
        background-color: #F5F5F5;

        .ant-input {
            background-color: #F5F5F5;
        }
    }

    .bc-switch {
        display: flex;
        align-self: center;
        margin-left: 42px;

        label {
            padding-right: 10px;
        }
    }
}

.filter-bar {
    .filter-selector {
        .ant-space-item {
            width: 138px;
        }
    }
}

.ant-progress-status-exception {
    .ant-progress-bg {
        background-color: #E10000 !important;
    }
}

.ant-progress-status-success {
    .ant-progress-bg {
        background-color: #0C9548 !important;
    }
}

.ant-progress-status-normal {
    .ant-progress-bg {
        background-color: #DABC20 !important;
    }
}

.chamber-overview-files-list{
    max-height: 350px;
    overflow-y: auto;
}