.detail-section-header-divider {
  margin-top: 10px;
  margin-bottom: 23px;
  border-color: #E1E1E1;
}

.static-details {
  margin-bottom: 22px;
  h3 {
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.005em;
  }

  .value {
    font-size: 16px;
    line-height: 19px;
    color: #92929D;
  }
}

.details-card {
  .ant-avatar, .image {
    border-radius: 8px;
    background-color: #E2E2EA;
    margin-right: 16px;
  }
  .details {
    .name {
      font-size: 16px;
      color: #11142D;
    }
    .id {
      font-size: 12px;
      color: #92929D;
    }
  }
  .image {
    width: 48px;
    height: 48px;
  }
}

.uploader {
  .ant-upload.ant-upload-drag p.ant-upload-drag-icon .anticon {
    color: #E1E1E1;
  }
  .ant-upload.ant-upload-drag {
    border: none;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23333' stroke-width='1' stroke-dasharray='10%2c 10' stroke-dashoffset='23' stroke-linecap='round'/%3e%3c/svg%3e");
    border-radius: 8px;
    padding-left: 18px;
    padding-right: 18px;
  }
  .ant-upload-list.ant-upload-list-text {
    margin: -40px 10px 0 10px;
    max-width: 230px;
  }
}

.site-view-tile {
  padding: 21px;
  box-shadow: 2px 6px 9px -2px rgba(0,0,0,0.5);
  -webkit-box-shadow: 2px 6px 9px -2px rgba(0,0,0,0.5);
  -moz-box-shadow: 2px 6px 9px -2px rgba(0,0,0,0.5);

  img.tile-image {
    width: 260px;
    height: 260px;
    flex: 0 0 260px;
    background-color: #BDC9D3;
    border: none;
  }

  .tile-content {
    .description {
      .title {
        font-size: 15px;
        margin: 12px 0;
      }
      .subtitle, .date {
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
      }
    }
  }
}
