.roster {

    .date-controller {
        .button {
            font-size: 24px;
            color: #012108;
            background-color: #E4E7E4;
            margin: 4px;
            border-radius: 20px;
            cursor: pointer;
        }

        .text {
            color: #012108;
            margin: 6px;
            margin-left: 8px;
        }
    }

    .filter-bar {
        display: flex;
        justify-content: space-between;

        .filter-selector {
            .ant-space-item {
                width: 138px;
            }
        }
    }

    .wrapper {
        display: flex;
        height: 100vh - 38;
        width: 100%;
        background-color: #F5F5F5;
        border-radius: 5px;
        margin-top: 10px;
        overflow: hidden;

        .chambers-list {
            margin-top: 30px;
            width: 230px !important;
            height: 100vh - 43;
            padding-bottom: 6px;
            overflow-y: auto;
            overflow-x: hidden;
            scrollbar-width: none; /* Firefox */
            -ms-overflow-style: none;  /* Internet Explorer 10+ */

            .chamber-item {
                display: flex;
                padding: 5px;
                justify-content: space-between;
                width: 190px !important;
                height: 80px;
                align-items: center;
                border-bottom: 1px solid #D6DDD7;
                border-right: 1px solid #D6DDD7;

                .labels {
                    padding-top: 4px;
                }
            }

            .item-0 {
                border-top: 1px solid #D6DDD7;
            }
        }

        .chambers-list::-webkit-scrollbar {
            display: none;
        }

        .calender-wrapper {
            width: 100%;
            height: fit-content;
            display: grid;
            grid-template-rows: auto 1fr;
            grid-template-areas: 
                    'header'
                    'main';
            height: 100vh - 38;
            overflow-y: auto;
            overflow-x: auto;

            .header {
                height: 30px !important;
                display: flex;
                grid-area: header;
                position: sticky;
                top: 0;
                z-index: 1000;
    
                .header-item {
                    min-width: 80px;
                    max-width: 80px;
                    font-size: 14px;
                    padding-left: 5px;
                    padding-top: 5px;
                    height: 30px !important;
                    border-bottom: 0.5px solid #D6DDD7;
                    background-color: #F5F5F5;
                }
            }

            .rows-container {
                grid-area: main;

                .row {
                    width: 100%;
                    display: flex;

                    .cell {
                        border-bottom: 1px solid #D6DDD7;
                        border-right: 1px solid #D6DDD7;
                        min-width: 80px !important;
                        height: 80px !important;
                    }
                }
            }

            .rows-values-container {
                z-index: 100;
                grid-area: main;

                .row {
                    display: flex;
                    width: 100%;

                    .is-left-bounded {
                        padding-left: 0px !important;
                    }

                    .is-right-bounded {
                        padding-right: 0px !important;
                    }

                    .card-wrapper {
                        display: 'flex';
                        padding: 8px;

                        .single-card {
                            display: flex;
                            width: 100%;
                            height: 100%;
                            border-radius: 10px;
                            justify-content: space-around;

                            .left {
                                display: flex;
                                flex-direction: column;
                                justify-content: center;
                                align-items: center;
                                padding-left: 2px;

                                .text {
                                    width: 38px;
                                    text-align: center !important;
                                    margin-top: 5px;
                                    font-size: 10px !important;
                                    font-weight: 600 !important;
                                }
                            }

                            .right{
                                display: flex;
                                justify-content: flex-end;
                                align-items: center;
                                cursor: pointer;
                            }
                        }

                        .milti-card{
                            display: flex;
                            width: 100%;
                            height: 100%;
                            border-radius: 10px;
                            justify-content: space-between;

                            .left{
                                display: flex;
                                align-items: center;
                                margin-left: 10px;

                                .text-wrapper{
                                    display: flex;
                                    margin-left: 10px;
                                    margin-top: 5px;
                                    overflow: hidden;
                                    white-space: nowrap;
                                    .text {
                                        width: 60px;
                                        text-align: left !important;
                                        font-size: 10px !important;
                                        font-weight: 600 !important;
                                        text-overflow: ellipsis;
                                    }
                                }
                            }

                            .right{
                                display: flex;
                                justify-content: flex-end;
                                align-items: center;
                                cursor: pointer;
                            }
                        }

                        .is-left-bounded-b {
                            border-top-left-radius: 0;
                            border-bottom-left-radius: 0;
                        }
    
                        .is-right-bounded-b {
                            border-top-right-radius: 0;
                            border-bottom-right-radius: 0;
                        }

                        .type-1 {
                            background-color: #BEDCF7;
                        }

                        .type-2 {
                            background-color: #ECCFD0;
                        }

                        .type-3 {
                            background-color: #D8CFEC;
                        }

                        .type-4 {
                            background-color: #F7D9BE;
                        }

                        .type-5 {
                            background-color: #F7F5BE;
                        }

                        .type-0 {
                            display: none;
                        }

                        .type-1-font {
                            color: #003E75 !important;
                        }

                        .type-2-font {
                            color: #990106 !important;
                        }

                        .type-3-font {
                            color: #3F1B8B !important;
                        }

                        .type-4-font {
                            color: #A25209 !important;
                        }

                        .type-5-font {
                            color: #797400 !important;
                        }

                    }

                }
            }
        }

        .drawer-button{
            
        }
    }
}