.build-admin-view {
    height: 68vh;
    overflow-x: auto;

    .ant-table-thead>tr>th {
        min-width: 215px !important;
    }
}

.admin-drawer {
    .header-comp{
        height: 15vh;
        .editable-label {
            width: 100%;
            justify-content: space-between !important;
    
            img {
                width: 15%;
                padding-left: 5px;
                padding-right: 5px;
            }
    
            .mid {
                width: 80%;
            }
    
            .ant-input {
                height: 17px;
                font-size: 11px;
                margin-right: 4px;
            }
    
            .right {
                width: 10%;
            }
        }
    }

    .comments-card {

        height: 40vh;

        .header {
            justify-content: space-between;
            align-items: center;

            .ant-btn {
                height: 25px;
                width: 105px;
                font-size: 10px;
                text-align: center;
                padding: 4px;
            }

            .ant-select {
                margin-right: 5px;
                border: 2px solid #D7F4DD;
                border-radius: 8px;

                &:focus {
                    box-shadow: 0 0 0 2px #00854088;
                }

                .ant-select-selector {
                    border: none;
                    padding-left: 0;

                    &:focus {
                        box-shadow: none;
                    }
                }

                &.ant-select-open {
                    box-shadow: 0 0 0 2px #00854088;

                    .ant-select-selector {
                        box-shadow: none;

                    }
                }
            }
        }

        .comment {
            .status {
                color: #000;
                line-height: 1;
                text-align: left;
                width: max-content;
                padding: 2px;
                margin-top: 2px;
                border-radius: 6px;
            }

            .shipping {
                background-color: #FAFF00;
            }

            .delivery {
                background-color: #70FF00;
            }

            .internal {
                background-color: #00F0FF;
            }
        }
    }

    .status-card{
        height: 30vh;
    }
}