@import 'sass/styles';
@import 'addons/dragAndDrop/styles'; // if using DnD

.user-multi-selector {
  background: white;
  width: 450px;
  padding: 15px;
  margin-left: 10px;
}

.task-type-selector {
    background: white;
    width: 450px;
    padding: 15px;
    max-height: 72px;
}

.sb-content {
    .rbc-calendar {
        height: 700px;
        margin-top: 10px;
        .rbc-toolbar {
            .rbc-btn-group {
                .rbc-active {
                    color: #008540;
                }
            }
        }

        .rbc-event {
            background-color: #51f34287;

            .rbc-event-content {
                color: #1b7b00;
                font-size: 10px;
            }
        }
        .rbc-time-view {
            .rbc-event {
                margin-top: 5px;
                border-bottom-right-radius: 5px;
                border-top-right-radius: 5px;

                .rbc-event-content{
                    font-size: 15px;
                }
            }
            .rbc-time-content {
                display: none;
            }
        }
    }
}

.events-wrapper{
    margin-top: 15px;
    margin-bottom: 15px;
    margin-left: 0px;
    margin-right: 3px;

    .rbc-toolbar{
        .rbc-toolbar-label{
            display: flex;
            justify-content: end;
        }
    }

    .pro-event {
        .date-card{
            padding: 15px;
            background-color: #008540;
            width: 100%;
            color: white;
            font-weight: 800;
            font-size: 22px;
            border-bottom-color: white;
            border-bottom-width: 2px;
            border-bottom: solid;
        }

        .event-card{
            background-color: rgb(251, 175, 175);
            color: rgb(255, 0, 0);
            margin-top: 5px;
            margin-bottom: 5px;
            margin-left: 20px;
            border-radius: 5px;
            padding: 15px;

            .profile-image {
                width: 36px;
                height: 36px;
                border-radius: 22px;
                border: solid;
                border-color: black;
                position: absolute;
                margin-left: -10px;
                margin-top: -5px;
            }

            .profile-image-with-text {
                width: 36px;
                height: 36px;
                border-radius: 22px;
                border: solid;
                border-color: black;
                position: absolute;
                margin-left: -10px;
                margin-top: -5px;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: rgb(93, 93, 93);

                .text-data {
                    color: white;
                    font-size: 17px;
                    font-weight: 600;
                }
            }

            .event-title-text{
                padding-left: 34px;
            }
        }
    }
}