.build-summary-chamber-view {

    .team-card {
        height: 136px;

        .right-arrow {
            color: #0C9548;
            font-size: 35px;
        }
    }

    .card-bg {
        background-color: #fff;
        border-radius: 10px;
    }

    .common-height {
        height: 100px;
    }

    .footer {
        max-height: 50px;

        .ant-list-items {
            max-height: 120px;
            overflow: auto;

            .footer-item {
                background-color: #BEDCF7;
                display: flex;
                padding: 10px;
                justify-content: space-around;
                align-items: center;
                margin-top: 12px;
                border-radius: 10px;

                .tc {
                    margin-right: -8px;
                }

                .down-arrow {
                    color: #0C9548;
                    font-size: 35px;
                }
            }
        }
    }

}