.detailed-reports {
  .summery-box {
    background-color: #4CA76A;
    color: white;
    .summery-title {
      font-size: 22px;
    }
    .summery-text {
      font-size: 14px;
    }
  }

  .service-card {
    background-color: white;
    height: 100%;
    margin: 0 5px;
    padding: 20px;
    img {
      margin-right: 10px;
    }
    .text {
      font-size: 13px;
      font-weight: 600;
    }
    .date {
      font-size: 26px;
      font-weight: 600;
    }
    .name, .email {
      font-size: 16px;
      overflow: hidden;
    }
  }

  .status-card {
    background-color: white;
    padding: 23px;
    border-radius: 8px;
    margin: 0 10px;

    .icon {
      color: #008540;
      font-size: 16px;
    }
    .count {
      font-size: 22px;
      font-weight: 500;
    }
    .description {
      font-size: 16px;
      font-weight: 400;
      color: #757575;
    }
  }
}
