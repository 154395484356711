.site-details {
  padding: 36px 70px;

  h3 {
    font-size: 16px;
    margin-bottom: 14px;
  }
  .address {
    margin-bottom: 26px;
  }

  .contract-container {
    overflow: auto;
    max-width: calc(100vw - 500px);
    .contract-cards {
      flex: 1 0;
      border: 1px solid #008540;
      border-radius: 8px;
      padding: 9px 21px;
      margin-right: 24px;
      .title {
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 8px;
      }
      .mgrName {
        font-size: 13px;
      }
      .mgrDetails {
        font-size: 11px;
      }
    }
  }
  .close-button {
    margin-top: 40px;
    margin-right: 24px;
  }
}
