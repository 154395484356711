.service-form {
  .q-selector {
    flex: 0 0 300px;
    max-height: calc(100vh - 200px);
    height: 100%;
    overflow: auto;
    padding-right: 10px;

    .ant-collapse-item {
      .ant-collapse-header {
        background-color: #008540;
        color: white;
        border-radius: 8px;
        padding: 18px;
        box-shadow: 2px 6px 9px -2px rgba(0,0,0,0.6);
        -webkit-box-shadow: 2px 6px 9px -2px rgba(0,0,0,0.6);
        -moz-box-shadow: 2px 6px 9px -2px rgba(0,0,0,0.6);
        margin-bottom: 15px;
        .q-header {
          font-size: 14px;
          letter-spacing: 0.005em;
        }
        .q-sub-title {
          font-size: 10px;
          letter-spacing: 0.005em;
        }

      }

      .ant-collapse-content-box {
        .ant-radio-group.ant-radio-group-outline {
          width: 85%;
          .ant-radio-button-wrapper {
            display: flex;
            align-items: center;
            padding: 18px;
            background-color: white;
            border-radius: 8px;
            box-sizing: content-box;
            margin-bottom: 15px;
            width: 100%;
            line-height: 1;

            box-shadow: 2px 6px 9px -2px rgba(0,0,0,0.4);
            -webkit-box-shadow: 2px 6px 9px -2px rgba(0,0,0,0.4);
            -moz-box-shadow: 2px 6px 9px -2px rgba(0,0,0,0.4);

            transition: margin-left .25s;

            span {
              width: 100%;
              .q-selector {
                display: flex;
                justify-content: space-between;
                overflow: hidden;
              }
            }

            &.ant-radio-button-wrapper-checked {
              background-color: #e6fff2;
              text-shadow: none;
              margin-left: 10px;

              box-shadow: 2px 6px 9px -2px rgba(0,0,0,0.6);
              -webkit-box-shadow: 2px 6px 9px -2px rgba(0,0,0,0.6);
              -moz-box-shadow: 2px 6px 9px -2px rgba(0,0,0,0.6);
            }
          }
        }
      }
    }

  }
  .q-container {
    max-height: calc(100vh - 200px);
    flex: 1;
    padding: 0 20px;
    height: 100%;
    overflow: overlay;

    .question {
      border-radius: 8px;
      background-color: white;

      box-shadow: 2px 6px 9px -2px rgba(0,0,0,0.4);
      -webkit-box-shadow: 2px 6px 9px -2px rgba(0,0,0,0.4);
      -moz-box-shadow: 2px 6px 9px -2px rgba(0,0,0,0.4);

      .ant-collapse-header-text {
        font-size: 13px;
        font-weight: 500;
        line-height: 13px;
        letter-spacing: 0.005em;
      }
      .ant-collapse-header.ant-collapse-header-collapsible-only {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px;
        &:after, &:before {
          content: none;
        }
      }

      .ant-collapse-item {
        margin-bottom: 20px;
      }

      .ant-collapse-extra {
        .header-icons {
          display: flex;
          .header-icon {
            font-size: 28px;
            margin: 0 13px;
            color: #008540;
            border-radius: 50%;
            padding: 2px;
            &.active {
              background-color: #008540;
              color: white;
            }

            &.camera {
              border-radius: 0;
              padding: 2px 0 2px 13px;
              margin-left: 0;
              border-left: 1px solid #EDF2F6;
            }
          }
        }
      }

      .radio-option {
        margin: 10px 0;
      }
    }
  }
}

.dynamic-form {
  .form-field {
    display: inline-flex;
    align-items: center;
    width: 31%;
    margin-right: 15px;

    input.ant-input {
      margin-left: 10px;
    }
  }
}

.note.sf-image-upload {
  div {
    span {
      font-size: 25px;
    }
  }
}