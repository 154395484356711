.chart{
    background-color: white;

    .wrapper{
        max-height: 344px;
        overflow: auto;
    }

    .chart-item{
        padding-left: 10px;
        padding-right: 10px;
        padding-bottom: 8px;
        padding-top: 8px;
        .item-title{
            width: 130px;
            text-align:left;
            height: 25px;
            vertical-align: middle;
            line-height: 25px;  
            font-size: 12px;
            font-weight: 450;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;  
            margin-right: 10px; 
        }
    
        .item-bar {
            height: 25px;
            background-color: #0C9548;
        }
    }
    .lower-bar{
        padding-left: 150px;
    }
}

.rounded_image{
    border-radius: 100px;
    text-align: center;
    vertical-align: middle;
    color: white;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
}

.rc-1 {background-color: #B55C5C;}
.rc-2 {background-color: #63B55C;}
.rc-3 {background-color: #785CB5;}
.rc-4 {background-color: rgb(159, 117, 0);}
.rc-5 {background-color: rgb(19, 110, 208);}